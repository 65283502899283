import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['source', 'filterable', 'articleItems', 'articleNenhumItem']

  initialize() {}

  connect () {}

  toggleMenu() {
    const menuButton = document.querySelector('#menu-deputados');
    menuButton.classList.toggle('ativo');
  }

  _closeMenu() {
    setTimeout(function () {
      $('#menu-deputados').removeClass('ativo')
    }, 100)
  }  

  filter() {
    let lowerCaseFilterTerm = this.source.toLowerCase()
    let lowerCaseFilterTermNormalize = lowerCaseFilterTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    let qtdeSemItems = 0

    this.filterableTargets.forEach((el, i) => {
      const filterableKey = el.getAttribute('data-filter-key')
      const filterableKeyNormalize = filterableKey.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

      el.classList.toggle('oculta', !filterableKeyNormalize.includes( lowerCaseFilterTermNormalize ) )
    })

    this.articleItemsTargets.forEach((el, i) => {
      const itemsSize = el.getAttribute('data-items-size')
      const qtdeTrOculta = el.querySelectorAll('tr.oculta').length
      el.classList.toggle('oculta', itemsSize == qtdeTrOculta)
      if (itemsSize == qtdeTrOculta) qtdeSemItems += 1
    })
    this.articleNenhumItemTarget.classList.toggle('oculta', qtdeSemItems != this.itemsSize())
    if (this.source.length > 0) document.querySelector('#fclear').classList.remove('oculta')
  }

  get source () {
    return this.sourceTarget.value
  }

  itemsSize () {
    const itemsSize = document.querySelector('#input-search').getAttribute('data-items-size')
    return itemsSize
  }


  resetFilter(event) {
    event.target.reset()
    setTimeout(() => {
      document.querySelector('#fclear').classList.add('oculta')
      this.articleItemsTargets.forEach((el, i) => el.classList.remove('oculta'))
      this.filter()
    }, 200);
  }

  copy(event) {
    try {
      setTimeout(() => {window.location.assign(event.target.href)}, 100)
      navigator.clipboard.writeText(event.target.href)
      
    } catch (error) {
      if (isSecureContext) console.error(error)
      else console.log(`Contexto inseguro . Conteúdo não copiado: <${event.target.tagName}>: ${event.target.href}`)
    }
  }
}