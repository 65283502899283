// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { Autocomplete } from 'stimulus-autocomplete'

import Flatpickr from 'stimulus-flatpickr'
import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"


flatpickr.localize(Portuguese)
flatpickr.setDefaults({dateFormat: 'd/m/Y'})

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

application.register('autocomplete', Autocomplete)
application.register('flatpickr', Flatpickr)
