import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['selectTipoAgenda']

    initialize() {}

    connect () {
        this._loading(false)
        $('#agenda_vazia').hide()

        if (agendas.length == 0)
            $('#agenda_vazia').show()
    }

    _loading (show) {
        if (show) {
            $('#modalCarregando').show()
        } else {
            $('#modalCarregando').hide()
        }
    }

    filtroAgendaChanged () {
        if (agendas.length > 0) {
            if (this.tipoAgenda == 'assembleia') {

                if (agendas.length == 2) {
                    $('#presidente').hide()
                    $('#agenda_vazia').hide()
                    $('#assembleia').show()

                } else if (agendas.length < 2 && agendas[0].tipo == 'assembleia') {
                    $('#presidente').hide()
                    $('#agenda_vazia').hide()
                    $('#assembleia').show()

                } else if (agendas.length < 2 && agendas[0].tipo != 'assembleia') {
                    $('#presidente').hide()
                    $('#agenda_vazia').show()
                    $('#assembleia').hide()

                    $('#agenda_vazia_titulo').text('Agenda da Assembleia')
                }

            } else if (this.tipoAgenda == 'presidente') {

                if (agendas.length == 2) {
                    $('#assembleia').hide()
                    $('#agenda_vazia').hide()
                    $('#presidente').show()

                } else if (agendas.length < 2 && agendas[0].tipo == 'presidente') {
                    $('#assembleia').hide()
                    $('#agenda_vazia').hide()
                    $('#presidente').show()

                } else if (agendas.length < 2 && agendas[0].tipo != 'presidente') {
                    $('#assembleia').hide()
                    $('#agenda_vazia').show()
                    $('#presidente').hide()

                    $('#agenda_vazia_titulo').text('Agenda do Presidente')
                }

            } else {
                $('#assembleia').show()
                $('#presidente').show()
                $('#agenda_vazia').hide()
            }
        }
    }

    get tipoAgenda () {
        return this.selectTipoAgendaTarget.value
    }
}