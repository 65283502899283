import { Controller } from 'stimulus'
 
export default class extends Controller {
  static targets = ["nome", "cargo", "rg", "cpf", "telefone", "celular", "endereco", "email", "veiculo", "midiaImpressa", "midiaOnline", "midiaRadio", "midiaTv", "midiaOutra", "checkMidiaOutra" ]
  
  initialize() {   
    this.midiaOutraTarget.style.display = 'none'     
  } 

  connect() {  
  }  

  disconnect() {
  }     
 
  mostrarOcultarOutroTipoVeiculo(){
    let none = this.checkMidiaOutraTarget.checked ? '' : 'none'
    this.midiaOutraTarget.style.display = none      
  }

 
  validarRecaptcha(event){   
    
    if(this.validaFormulario(event)){
          
      let valorRecaptcha = $('#g-recaptcha-response').val();      
      
      if(!valorRecaptcha){
        swal({
          title: 'Credenciamento',
          text: "Favor resolver o reCAPTCHA",
          type: "warning"
        });
        event.preventDefault();
        return false;

      }else {
        let url = this.data.get('url').split('/')[1]+'/validar_recaptcha';       
        
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "valor_recaptcha": valorRecaptcha })
        })
        .then((response) => response.json())  
          .then((data) => {              
            if(!data.captcha_valido & !valorRecaptcha){              
              swal({
                title: 'Credenciamento',
                text: "Favor resolver o reCAPTCHA.",
                type: "warning"
              });
              event.preventDefault();
              return false;
            } 
            return true;          
          })     
        .catch((error) => {
          console.log(error)   
          event.preventDefault();     
          return false;
        });   
      }  
    } 
  }  

  validaFormulario(event){  
    let campoObrigatorio = []  

    if(!this.nomeTarget.value){      
      campoObrigatorio.push(" Nome");       
    }

    if(!this.cargoTarget.value){     
      campoObrigatorio.push(" Cargo");      
    }

    if(!this.rgTarget.value){
      campoObrigatorio.push(" RG");      
    }

    if(!this.cpfTarget.value){
      campoObrigatorio.push(" CPF");      
    }

    if(!this.telefoneTarget.value){
      campoObrigatorio.push(" Telefone");
    }

    if(!this.celularTarget.value){
      campoObrigatorio.push(" Celular");
    }

    if(!this.enderecoTarget.value){
      campoObrigatorio.push(" Endereço");
    }

    if(!this.emailTarget.value){
      campoObrigatorio.push(" Email");
    }else if(!this.validaEmail(this.emailTarget)){
      campoObrigatorio.push(" Email válido");
    }

    if(!this.veiculoTarget.value){
      campoObrigatorio.push(" Veículo de Comunicação");
    }

    if(!this.midiaOutraTarget.value){
      if(!this.midiaImpressaTarget.checked & !this.midiaOnlineTarget.checked & !this.midiaRadioTarget.checked & !this.midiaTvTarget.checked){
        campoObrigatorio.push(" Tipo de Veículo")
      }
    }  
 
    if(campoObrigatorio.length){
      campoObrigatorio.unshift("Favor informar o(s) campo(s) ")
      swal({
        title: "Credenciamento",
        text: campoObrigatorio.toString(),
        type: "warning",
      });     
      event.preventDefault();
      return false;
    }else{
      return true
    }  
  }


  validaEmail(email){
    var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if(!filter.test(email.value)){
      return false;
    } else {
     return true;
    }
  }

}