import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['nome', 'email', 'mensagem', 'contatadoId', 'contatadoType']
  static values = { url: String, pagina: String }

  initialize() {}

  connect () {
    this._loading(false)
  }

  _loading (show) {
    if (show) {
        $('#modalCarregando').show()
    } else {
        $('#modalCarregando').hide()
    }
  }

  validarRecaptcha(event){
    if (this.validaFormulario(event)) {
      const loading = this._loading
      let valorRecaptcha = $('#g-recaptcha-response').val()

      if (!valorRecaptcha) {
        swal({
          title: 'Fale Conosco',
          text: "Favor resolver o reCAPTCHA",
          type: "warning"
        });

        event.preventDefault();
        return false;
      } else {

        event.preventDefault()
        event.stopImmediatePropagation()
        const form = document.getElementById('form-contatos')
        loading(true)

        fetch(this.urlValue, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "valor_recaptcha": valorRecaptcha })
        })
        .then((response) => response.json())
        .then((data) => {
          if (!data.captcha_valido & !valorRecaptcha) {
            swal({
              title: 'Fale Conosco',
              text: "Favor resolver o reCAPTCHA.",
              type: "warning"
            })
            event.preventDefault()
            loading(true)
            return false
          }

          const url = `/contatos/${this.paginaValue}`
          const urlParams = `${url}?${$(form).serialize()}`

          fetch(urlParams, { method: 'POST' })
          .then((response) => {
            if (response.ok) {
              swal({
                title: "Fale conosco",
                text: "Mensagem enviada com sucesso!",
                type: "success",
                showCancelButton: false,
                closeOnConfirm: true,
                showLoaderOnConfirm: false
              }, function () {
                window.location.href = url
              })
            } else {
              swal({
                title: 'Fale Conosco',
                text: "Não foi possível enviar a mensagem. Tente novamente mais tarde.",
                type: "warning"
              })
              loading(false)
            }
          })
          .catch((error) => {
            console.log('error', error)
            event.preventDefault()
            loading(false)
            return false
          })
        })
        .catch((error) => {
          console.log('error', error)
          event.preventDefault()
          loading(false)
          return false
        })
      }
    }
  }

  validaFormulario(event) {
    let campoObrigatorio = []

    if (!this.contatadoIdTarget.value) {
      if (this.contatadoTypeTarget.value == 'Public::Deputado') {
        campoObrigatorio.push(" Deputado(a)")
      } else if (this.contatadoTypeTarget.value == 'Public::Comissao') {
        campoObrigatorio.push(" Comissão")
      }
    }

    if (!this.nomeTarget.value) {  
      campoObrigatorio.push(" Nome")
    }

    if (!this.emailTarget.value) {
      campoObrigatorio.push(" Email")
    } else if (!this.validaEmail(this.emailTarget)) {
      campoObrigatorio.push(" Email válido")
    }

    if (!this.mensagemTarget.value) {
      campoObrigatorio.push(" Mensagem")
    }

    if (campoObrigatorio.length > 0) {
      campoObrigatorio.unshift("Favor informar o(s) campo(s)")
      swal({
        title: "Fale Conosco",
        text: campoObrigatorio.toString(),
        type: "warning",
      });
      event.preventDefault();
      return false
    } else {
      return true
    }  
  }

  validaEmail(email){
    var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!filter.test(email.value)) {
      return false
    } else {
      return true
    }
  }
}
