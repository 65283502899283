import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["divInputData"]

  initialize () {
    // Is run first. In this case we don't need to worry about anything.
  }

  connect () {
    this._loading(false)
    this._datePickerConfig('/dias-do-mes-com-processos')
  }

  _loading (show) {
    if (show) {
        $('#modalCarregando').show()
    } else {
        $('#modalCarregando').hide()
    }
  }

  _datePickerConfig (urlParams) {
    const diasMarcado = this.divInputDataTarget.getAttribute('data-dias-marcado')
    const rangeAnos = this.divInputDataTarget.getAttribute('data-range-anos')
    const paramsData = new Date(this.divInputDataTarget.getAttribute('data-params-date'))
    const loading = this._loading

    $('#data').datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: 'dd/mm/yy',
      dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro',
        'Outubro', 'Novembro', 'Dezembro'
      ],
      monthNamesShort: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto',
        'Setembro',
        'Outubro', 'Novembro', 'Dezembro'
      ],
      nextText: 'Proximo',
      prevText: 'Anterior',
      defaultDate: paramsData,
      yearRange: rangeAnos,
      beforeShowDay: function (date) {
        // let dia = this._formatarData(date)
        let dia = date.getFullYear().toString()

        if (date.getMonth() < 9) { // getMonth começa com o indice 0
          dia += '/0' + (date.getMonth() + 1)
        } else {
          dia += '/' + (date.getMonth() + 1)
        }

        if (date.getDate() < 10) {
          dia += '/0' + date.getDate()
        } else {
          dia += '/' + date.getDate()
        }

        if (diasMarcado.indexOf(dia) != -1) {
          // add class na td caso possua evento no dia
          return [true, 'ui-datepicker-evento']
        } else {
          return [true, '']
        }
      },
      onChangeMonthYear: function (ano, mes, datepicker) {
        const url = `${urlParams}?ano=${ano}&mes=${mes}`

        $.get(url, function (response) {
          const diasMarcado = response
          // array de tags [a] do datepicker. obs: caso a class da table do datepicker mudar
          // (até a implementação é '.ui-datepicker-calendar'), deve alterar aqui também
          let dias = $('.ui-datepicker-calendar a')

          dias.each(function (index, a) {
            let dia = new Date(ano, mes - 1, parseInt(a.text)) // passa o texto da tag [a] para inteiro e pega a data

            // let dia_formatado = this._formatarData(dia)
            let dia_formatado = dia.getFullYear().toString()

            if (dia.getMonth() < 9) { // getMonth começa com o indice 0
              dia_formatado += '/0' + (dia.getMonth() + 1)
            } else {
              dia_formatado += '/' + (dia.getMonth() + 1)
            }

            if (dia.getDate() < 10) {
              dia_formatado += '/0' + dia.getDate()
            } else {
              dia_formatado += '/' + dia.getDate()
            }

            let primerio_dia_mes = new Date(ano, mes - 1, 1) // pego primeiro dia do mes para obter o indice do inicio da semana

            if (diasMarcado.indexOf(dia_formatado) != -1) {
              // caso as [td] da table tenha alguma com espaços em branco,
              // eu somo ao index o valor do indice do inicio da semanha para poder add a class na posição correta
              $('.ui-datepicker-calendar td').eq(index + primerio_dia_mes.getDay()).addClass('ui-datepicker-evento')

            } else {
              $('.ui-datepicker-calendar td').eq(index + primerio_dia_mes.getDay()).removeClass('ui-datepicker-evento')
            }
          })
        })
      },
      onSelect: function (data) {
        loading(true)
        var data = $('#data').val()
        var url = '/pauta-previa?data=' + data

        window.location = url
      }
    })
  }
}
