import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "galeriaImagem", "galeriaImagemSelecionada", "seletorPosicao", "carouselImagens"
  ]

  initialize() {
  }

  connect() {

    if (location.hash) {

      const indexImg = parseInt(location.hash.replace('#', ''))

      if (indexImg < this.galeriaImagemTargets.length)
        this.index = indexImg

    }
  }

  disconnect() {
  }

  imagemSelecionada({target}) {
    const span = target.parentElement
    const indexImagem = parseInt(span.getAttribute('data-imagem-index'))

    this.index = indexImagem
  }

  expandir({target}) {
    this.posicao = this.posicao + 1
  }

  recolher({ target }) {
    this.posicao = this.posicao - 1
  }

  previousCarrousel() {

    const imgSpan = this
                      .galeriaImagemTargets[0]
                      .querySelector('img')

    //Movimenta o carrousel
    const heightImgThumb = imgSpan.height

    this.carouselImagensTarget.scrollTop = this.carouselImagensTarget.scrollTop -
      heightImgThumb - 5
  }

  forwardCarrousel() {

    const imgSpan = this
                      .galeriaImagemTargets[0]
                      .querySelector('img')

    //Movimenta o carrousel
    const heightImgThumb = imgSpan.height

    this.carouselImagensTarget.scrollTop = this.carouselImagensTarget.scrollTop +
      heightImgThumb + 5

  }

  _atribuirImagemSelecionada() {

    const span = this.galeriaImagemTargets[this.index]

    //Marca a classe selecionada
    this.galeriaImagemTargets.forEach(spanElement => {
      spanElement.classList.remove('selecionada')
    })
    span.classList.add('selecionada')


    //Carrega a imagem
    const imagemOriginalUrl = span.getAttribute('data-imagem-original-url')
    const imagemGrandeUrl = span.getAttribute('data-imagem-grande-url')
    const imagemTitulo = span.getAttribute('data-imagem-titulo')
    const imagemCredito = span.getAttribute('data-imagem-credito')

    const img = this
                  .galeriaImagemSelecionadaTarget
                  .querySelector('img')

    const aBaixarImagem = this
                            .galeriaImagemSelecionadaTarget
                            .querySelector('a')
    
    const descricaoFoto = this
                            .galeriaImagemSelecionadaTarget
                            .querySelector('figcaption')
    const creditoFoto = this
                            .galeriaImagemSelecionadaTarget
                            .querySelector('#credito-galeria')


    aBaixarImagem.setAttribute('href', imagemOriginalUrl)
    
    img.setAttribute('src', imagemGrandeUrl)
    img.setAttribute('alt', imagemTitulo)
    
    descricaoFoto.textContent = imagemTitulo
    creditoFoto.textContent = imagemCredito

    if(this.posicao == 3) {
      setTimeout(() => {
        this.posicao = 2
      }, 250)
    }

  }

  proximaImagem() {
    this.index++
  }

  imagemAnterior() {
    this.index--
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    const ultimoIndex = this.galeriaImagemTargets.length - 1

    if (value > ultimoIndex) {
      value = 0
    }else if (value < 0) {
      value = ultimoIndex
    }

    this.data.set("index", value)
    this._atribuirImagemSelecionada()
    location.hash = '#' + value
  }


  get posicao() {
    return parseInt(this.data.get("posicao"))
  }

  set posicao(value) {

    this
      .seletorPosicaoTarget
      .classList
      .remove(`galeria-fotos__show--posicao${this.posicao}`)


    this
      .seletorPosicaoTarget
      .classList
      .add(`galeria-fotos__show--posicao${value}`)

    this.data.set("posicao", value)
    
    if(this.posicao == 3){
      $('#navegador').css("opacity", "0");
      $('#navegador').css("pointer-events", "none");
    } else {
      $('#navegador').css("opacity", "1");
      $('#navegador').css("pointer-events", "");
    }
  }

}
