import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['nome', 'email', 'assunto', 'mensagem']
    static values = { url: String, contatado: String }

    initialize() {

    }

    connect() { }

    _loading(show) {
        if (show) {
            $('#modalCarregando').show()
        } else {
            $('#modalCarregando').hide()
        }
    }

    validarRecaptcha(event) {
        if (this.validaFormulario(event)) {
            const loading = this._loading
            let valorRecaptcha = $('#g-recaptcha-response').val()

            if (!valorRecaptcha) {
                swal({
                    title: 'Contato',
                    text: "Favor resolver o reCAPTCHA",
                    type: "warning"
                });

                event.preventDefault();
                return false;
            } else {

                event.preventDefault()
                event.stopImmediatePropagation()
                const form = document.getElementById('form-contato-site')

                loading(true)

                fetch(this.urlValue, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "valor_recaptcha": valorRecaptcha })
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (!data.captcha_valido & !valorRecaptcha) {
                            swal({
                                title: 'Contato',
                                text: "Favor resolver o reCAPTCHA.",
                                type: "warning"
                            })
                            event.preventDefault()
                            loading(true)
                            return false
                        }

                        const url = "/sites/contato/"
                        const urlBase = "/sites/lgpd"

                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                contato: {
                                    nome: this.nomeTarget.value,
                                    email: this.emailTarget.value,
                                    assunto: this.assuntoTarget.value,
                                    mensagem: this.mensagemTarget.value,
                                    contatado: this.contatadoValue,
                                }
                            })
                        })
                            .then((response) => {
                                if (response.ok) {
                                    swal({
                                        title: "Contato",
                                        text: "Mensagem enviada com sucesso!",
                                        type: "success",
                                        showCancelButton: false,
                                        closeOnConfirm: true,
                                        showLoaderOnConfirm: false
                                    }, function () {
                                        window.location.href = urlBase;
                                    })
                                } else {
                                    swal({
                                        title: 'Contato',
                                        text: "Não foi possível enviar a mensagem. Tente novamente mais tarde.",
                                        type: "warning"
                                    })
                                    loading(false)
                                }
                            })
                            .catch((error) => {
                                console.log('error', error)
                                event.preventDefault()
                                loading(false)
                                return false
                            })
                    })
                    .catch((error) => {
                        console.log('error', error)
                        event.preventDefault()
                        loading(false)
                        return false
                    })
            }
        }
    }

    validaFormulario(event) {
        let campoObrigatorio = []

        if (!this.nomeTarget.value) {
            campoObrigatorio.push(" Nome")
        }

        if (!this.emailTarget.value) {
            campoObrigatorio.push(" Email")
        } else if (!this.validaEmail(this.emailTarget)) {
            campoObrigatorio.push(" Email inválido")
        }

        if (!this.assuntoTarget.value) {
            campoObrigatorio.push(" Assunto")
        }

        if (!this.mensagemTarget.value) {
            campoObrigatorio.push(" Mensagem")
        }

        if (campoObrigatorio.length > 0) {
            campoObrigatorio.unshift("Favor informar o(s) campo(s)")
            swal({
                title: "Contato",
                text: campoObrigatorio.toString(),
                type: "warning",
            });
            event.preventDefault();
            return false
        } else {
            return true
        }
    }

    validaEmail(email) {
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        if (!filter.test(email.value)) {
            return false
        } else {
            return true
        }
    }
}
