import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  initialize() { }

  connect() {
    this.selectMenu()
  }

  toggleMenu() {
    const menuButton = document.querySelector('#menu-procuradoria');
    menuButton.classList.toggle('ativo');
  }

  selectMenu() {
    if (location.href.indexOf('#institucional') != -1) {
      this.abaInstitucional()
    } else if (location.href.indexOf('#jurisprudencia-especifica') != -1) {
      this.abaJurisprudenciaEspecifica()
    } else if (location.href.indexOf('#revista-advocacia') != -1) {
      this.abaRevistaAdvocacia()
    } else if (location.href.indexOf('#producao-academica') != -1) {
      this.abaProducaoAcademica()
    } else if (location.href.indexOf('#estudos') != -1) {
      this.abaEstudos()
    } else if (location.href.indexOf('#biblioteca') != -1) {
      this.abaBiblioteca()
    } else if (location.href.indexOf('#legislacao') != -1) {
      this.abaLegislacao()
    } else if (location.href.indexOf('#publicacoes') != -1) {
      this.abaPublicacoes()
    } else if (location.href.indexOf('#informativos') != -1) {
      this.abaInformativos()
    } else {
      this.abaInstitucional()
    }
  }


  abaInstitucional() {
    // select aba
    this._ativarAba('institucional');
    this._inativarAba('jurisprudencia-especifica');
    this._inativarAba('revista-advocacia');
    this._inativarAba('producao-academica');
    this._inativarAba('estudos');
    this._inativarAba('biblioteca');
    this._inativarAba('informativos');
    this._inativarAba('legislacao');
    this._inativarAba('publicacoes');

    this._closeMenu()
  }

  abaJurisprudenciaEspecifica() {
    // select aba
    this._ativarAba('jurisprudencia-especifica');
    this._inativarAba('institucional');
    this._inativarAba('revista-advocacia');
    this._inativarAba('producao-academica');
    this._inativarAba('estudos');
    this._inativarAba('biblioteca');
    this._inativarAba('informativos');
    this._inativarAba('legislacao');
    this._inativarAba('publicacoes');

    this._closeMenu()
  }

  abaRevistaAdvocacia() {
    // select aba
    this._ativarAba('revista-advocacia');
    this._inativarAba('jurisprudencia-especifica');
    this._inativarAba('institucional');
    this._inativarAba('producao-academica');
    this._inativarAba('estudos');
    this._inativarAba('biblioteca');
    this._inativarAba('informativos');
    this._inativarAba('legislacao');
    this._inativarAba('publicacoes');

    this._closeMenu()
  }

  abaProducaoAcademica() {
    // select aba
    this._ativarAba('producao-academica');
    this._inativarAba('revista-advocacia');
    this._inativarAba('jurisprudencia-especifica');
    this._inativarAba('institucional');
    this._inativarAba('estudos');
    this._inativarAba('biblioteca');
    this._inativarAba('informativos');
    this._inativarAba('legislacao');
    this._inativarAba('publicacoes');

    this._closeMenu()
  }

  abaEstudos() {
    // select aba
    this._ativarAba('estudos');
    this._inativarAba('producao-academica');
    this._inativarAba('revista-advocacia');
    this._inativarAba('jurisprudencia-especifica');
    this._inativarAba('institucional');
    this._inativarAba('biblioteca');
    this._inativarAba('informativos');
    this._inativarAba('legislacao');
    this._inativarAba('publicacoes');

    this._closeMenu()
  }

  abaBiblioteca() {
    // select aba
    this._ativarAba('biblioteca');
    this._inativarAba('estudos');
    this._inativarAba('producao-academica');
    this._inativarAba('revista-advocacia');
    this._inativarAba('jurisprudencia-especifica');
    this._inativarAba('institucional');
    this._inativarAba('informativos');
    this._inativarAba('legislacao');
    this._inativarAba('publicacoes');

    this._closeMenu()
  }

  abaInformativos() {
    // select aba
    this._ativarAba('informativos');
    this._inativarAba('biblioteca');
    this._inativarAba('estudos');
    this._inativarAba('producao-academica');
    this._inativarAba('revista-advocacia');
    this._inativarAba('jurisprudencia-especifica');
    this._inativarAba('institucional');
    this._inativarAba('legislacao');
    this._inativarAba('publicacoes');

    this._closeMenu()
  }

  abaLegislacao() {
    // select aba
    this._ativarAba('legislacao');
    this._inativarAba('informativos');
    this._inativarAba('biblioteca');
    this._inativarAba('estudos');
    this._inativarAba('producao-academica');
    this._inativarAba('revista-advocacia');
    this._inativarAba('jurisprudencia-especifica');
    this._inativarAba('institucional');
    this._inativarAba('publicacoes');

    this._closeMenu()
  }

  abaPublicacoes() {
    // select aba
    this._ativarAba('publicacoes');
    this._inativarAba('informativos');
    this._inativarAba('biblioteca');
    this._inativarAba('estudos');
    this._inativarAba('producao-academica');
    this._inativarAba('revista-advocacia');
    this._inativarAba('jurisprudencia-especifica');
    this._inativarAba('institucional');
    this._inativarAba('legislacao');

    this._closeMenu()
  }


  _closeMenu() {
    setTimeout(function () {
      $('#menu-procuradoria').removeClass('ativo')
    }, 100)
  }

  _ativarAba(aba) {
    $('#label-' + aba).addClass('ativo');
    $('#article-' + aba).addClass('ativa');
  }
  _inativarAba(aba) {
    $('#label-' + aba).removeClass('ativo');
    $('#article-' + aba).removeClass('ativa');
  }

}