import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "seletorGrid", "linha", "selectCategoria", "divAutocomplete",
                    "inputDataFinal"]

  initialize() {
    this._onChangeCategoria = this._onChangeCategoria.bind(this)
  }

  connect() {
    this.selectCategoriaTarget.addEventListener('change', this._onChangeCategoria)

    this._aplicarTipoRelacionado()
    this._resolverIntervaloDatas()
  }

  disconnect() {
    this.selectCategoriaTarget.removeEventListener('change', this._onChangeCategoria)
  }

  _onChangeCategoria() {
    this._aplicarTipoRelacionado()
  }

  _aplicarTipoRelacionado() {

    const tipoRelacionado = this.tipoRelacionadoCategoria


    if (tipoRelacionado) {
      this.divAutocompleteTarget
          .querySelector('label')
          .textContent = this._resolveNomeRelacionamento(tipoRelacionado)

      this
        .divAutocompleteTarget
        .setAttribute('data-autocomplete-url', `/galerias/autocomplete.html?tipo_relacionado=${tipoRelacionado}`)

      this.divAutocompleteTarget.classList.remove('oculta')
    }else {
      this.divAutocompleteTarget.classList.add('oculta')
    }

  }

  _resolverIntervaloDatas() {
    const radioSelecionado = this.radioPeriodoSelecionado
    this.inputDataFinalTarget.classList.toggle('oculta', radioSelecionado != 'periodo')
  }

  _resolveNomeRelacionamento(nome) {

    switch (nome) {
      case 'comissao':
        return 'Comissão'
        break;
      case 'cpi':
        return 'CPI(s)'
        break;
      case 'deputado':
        return 'Deputado(a)'
        break;
      default:
        return ''

    }

  }

  setSeletorAtivo(event) {
    event.preventDefault()

    let elementeRef = event.target
    if (event.target.tagName !== 'div') {
      elementeRef = event.target.closest('div')
    }

    this.seletorGridTargets.forEach(el => el.classList.remove('visualizacao-ativa'))
    elementeRef.classList.add('visualizacao-ativa')


    const gridNumero = parseInt(elementeRef.getAttribute('data-grid-numero'))

    this.linhaTargets.forEach(el => {
      el.classList.remove(`grid-${this.gridAtivoNumero}`)
      el.classList.add(`grid-${gridNumero}`)
    })

    this.gridAtivoNumero = gridNumero
  }

  checarPeriodo() {
    this._resolverIntervaloDatas()
  }


  get seletorAtivo() {
    return this
            .seletorGridTargets
            .find(seletor => seletor.classList.contains('visualizacao-ativa'))
  }

  get gridAtivoNumero() {
    return this.data.get('gridAtivoNumero')
  }

  get tipoRelacionadoCategoria() {
    return this
            .selectCategoriaTarget
            .selectedOptions[0]
            .getAttribute('data-tipo-relacionado')
  }

  get radioPeriodoSelecionado() {
    return this
            .element
            .querySelector("input[name=periodo_selecionado]:checked")
            .value
  }

  set gridAtivoNumero(gridAtivoNumero) {
    this.data.set('gridAtivoNumero', gridAtivoNumero)
  }

}
